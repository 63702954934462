
/**
 * ¡IMPORTANTE!
 *
 * Este archivo es autogenerado por el script "version.js", no
 * modificarlo manualmente, ni pushearlo al repo.
 *
 */

export const VERSION = {
    "dirty": false,
    "raw": "9aa08eed0",
    "hash": "9aa08eed0",
    "distance": null,
    "tag": null,
    "semver": null,
    "suffix": "9aa08eed0",
    "semverString": null,
    "version": "2.20.2"
};

